var _a;
import { __awaiter, __generator } from "tslib";
import loadable from '@loadable/component';
export default [
    {
        id: '@supplier-setting',
        path: '/supplier-setting',
        ref: '/',
        onload: function () { return import('./supplier-setting-view'); },
        onfirst: function (app) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, app.store
                            .dynamic('@supplier-setting', function () { return import('./supplier-setting.store'); })
                            .load('@supplier-setting')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
    },
    {
        point: '@@components',
        namespace: '@supplier-setting',
        onfirst: function (app) { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, app.store
                            .dynamic('@supplier-setting', function () { return import('./supplier-setting.store'); })
                            .load('@supplier-setting')];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); },
        onload: function () { return [
            { key: 'SupplierEnter', component: function () { return import('./supplierEnter/supplier-enter-view'); } },
        ]; },
    },
    {
        point: '@@menus',
        onload: function () { return [
            {
                id: 'supplier-setting',
                pId: 'supplier',
                permissions: ['SYS_ADMIN'],
                weight: 10,
                href: '/supplier-setting',
                icon: 'role-manage',
            },
        ]; },
    },
    {
        point: '@@layers',
        prefix: '@supplier-setting',
        onload: function () { return import('./layers'); },
    },
    {
        resource: '@supplier-setting',
        value: (_a = {},
            _a['components/fieldSetting'] = loadable(function () { return import('./components/fieldSetting'); }),
            _a),
    },
];
